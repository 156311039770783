<template>
  <v-row>
    <px-loading-horizontal-cards :loading="loading"/>
    <v-col cols="6" sm="3" v-for="departamento in departamentos" :key="departamento.id">
      <px-departamento-card :img="images[departamento.id]" :departamento="departamento"/>
    </v-col>
  </v-row>
</template>

<script>
import DepartamentoCard from '@/modules/home-page/DepartamentoCard.vue';
import img from '@/assets/landing-page/suprimentos/copos-mobile.png';
import DepartamentoService from '@/modules/departamentos-menu/menu-service';
import caixas from '@/assets/landing-page/suprimentos/departamentos/caixas.png';
import informatica from '@/assets/landing-page/suprimentos/departamentos/materiais-informatica.png';
import escritorio from '@/assets/landing-page/suprimentos/departamentos/materiais-escritorio.png';
import limpeza from '@/assets/landing-page/suprimentos/departamentos/limpeza-descartaveis.png';

export default {
  props: {
    query: {
      type: String,
      default: 'ativo is true',
    },
  },
  data() {
    return {
      img,
      loading: false,
      departamentos: [],
      // gambiarra pq temos imagens nos departados
      // todo: fix no futuro
      images: {
        23: caixas,
        34: informatica,
        24: escritorio,
        17: limpeza,
      },
    };
  },
  methods: {
    getDepartamentos() {
      this.loading = true;
      DepartamentoService.getDepartamentosOnly({
        limit: 4,
        query: this.query,
      })
        .then(({ data }) => {
          this.departamentos = data.content;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getDepartamentos();
  },
  components: {
    pxDepartamentoCard: DepartamentoCard,
  },
};
</script>
